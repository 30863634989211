import React, {useState, useEffect, Fragment, useRef} from "react";
import "./App.css"
// import firebase from "./Firebase.js"
// import { signInWithGoogle } from "./Firebase.js";
import { v4 as uuidv4} from 'uuid';
import {Navigate} from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore"
import {getAuth, GoogleAuthProvider, signInWithPopup, signOut} from 'firebase/auth';
import GoogleButton from 'react-google-button'

// import { Switch, Route, Redirect } from "react-router-dom";

function Admin() {

    const auth = getAuth();

    const provider = new GoogleAuthProvider();

  const [signInVerified, setSignInVerified] = React.useState(false); 
 
  if (signInVerified){
    return(<Navigate to="/admin"/>)
  }
  
  function signInWithGoogle(){
    signInWithPopup(auth, provider)
      .then((result) => {
        const name = result.user.displayName;
        const email = result.user.email;
        const profilePic = result.user.photoURL;
  
        localStorage.setItem("name", name);
        localStorage.setItem("email", email);
        localStorage.setItem("profilePic", profilePic);
        console.log(localStorage.getItem("email"));
  
        if (localStorage.getItem("email") == "tulsigrocer928@gmail.com" || localStorage.getItem("email") == "karan.kashyap.4899@gmail.com" || localStorage.getItem("email") == "devpatel05@gmail.com"){
          console.log("success");
          setSignInVerified(true);
        } else {
          console.log("BAD");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }


    return (
      <>




<div class = "sign-in-div">

      <GoogleButton
  onClick={() => {signInWithGoogle();}}
/>
      </div>

      {/* <div>
        <h1>Items</h1>
          {items.map((item) => (
            <div>
            <h3>{item.name}</h3>
            <p>${item.price}</p>
            <p>Quantity: {item.quantity}</p>
            </div>
          ))}
      </div> */}

      


   

      
          
      </>
    );

   
  }
  
  
  export default Admin;
