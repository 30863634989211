import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css'
import Image from 'react-bootstrap/Image'
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';
import React, { useState, useEffect } from 'react';
import './App.css';
import Aos from "aos";
import "aos/dist/aos.css"



function CarouselPics() {


    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {

        Aos.init();
    }, []);
    return (
        <>

          
                <Carousel controls = {true} className="carous" variant="dark" activeIndex={index} onSelect={handleSelect}>


                    <Carousel.Item interval={3000}>
                        <img
                            className="carouselpics"
                            // change to local image later
                            src={require('./images/store-pics/front-outside-blurred.jpg')}
                            alt="First slide"
                        />
                        <Carousel.Caption className = "carousel-caption">
                            <h3 data-aos="fade-up" data-aos-duration="1500" className="carouseltext">Welcome to Tulsi Grocers!</h3>
                            <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" data-aos-offset = "10px" className="carouseltext2">Fresh produce and food available every day!</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={3000} >
                        <img
                            className="carouselpics"
                            // change to local image later
                            src={require('./images/store-pics/aisle-3-back-to-front.jpg')}
                            alt="Second slide"
                        />

                        <Carousel.Caption>
                            <h3 className="carouseltext">Welcome to Tulsi Grocers!</h3>
                            <p className="carouseltext2">Fresh produce and food available every day!</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item  interval={3000}>
                        <img
                            className="carouselpics"
                            // change to local image later
                            src={require('./images/store-pics/big-fridge.jpg')}
                            alt="Third slide"
                        />

                        <Carousel.Caption>
                            <h3 className="carouseltext">Welcome to Tulsi Grocers!</h3>
                            <p className="carouseltext2">Fresh produce and food available every day!</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                        <img
                            className="carouselpics"
                            // change to local image later
                            src={require('./images/store-pics/fresh-vegetables.jpg')}
                            alt="Third slide"
                        />

                        <Carousel.Caption>
                            <h3 className="carouseltext">Welcome to Tulsi Grocers!</h3>
                            <p className="carouseltext2">Fresh produce and food available every day!</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                        <img
                            className="carouselpics"
                            // change to local image later
                            src={require('./images/store-pics/back-aisle-shot.jpg')}
                            alt="Third slide"
                        />

                        <Carousel.Caption>
                            <h3 className="carouseltext">Welcome to Tulsi Grocers!</h3>
                            <p className="carouseltext2">Fresh produce and food available every day!</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                        <img
                            className="carouselpics"
                            // change to local image later
                            src={require('./images/store-pics/small-fridge.jpg')}
                            alt="Third slide"
                        />

                        <Carousel.Caption>
                            <h3 className="carouseltext">Welcome to Tulsi Grocers!</h3>
                            <p className="carouseltext2">Fresh produce and food available every day!</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                        <img
                            className="carouselpics"
                            // change to local image later
                            src={require('./images/store-pics/rice-area.jpg')}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3 className="carouseltext">Welcome to Tulsi Grocers!</h3>
                            <p className="carouseltext2">Fresh produce and food available every day!</p>
                        </Carousel.Caption>
                    </Carousel.Item>


                </Carousel>

         
        </>
    );
}


export default CarouselPics;