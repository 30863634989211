//npm install react-scripts --save

import Navb from './Navb.js';
import CarouselPics from './CarouselPics.js';
import About from './About.js'
import FAQ from './faq.js'
import Contact from './Contact.js'
import Products from './Products.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Router, useState, useEffect } from 'react';
import Reviews from './Reviews.js';
import Footer from './Footer.js';




function App() {
  const [faqs, setFaqs] = useState([
    {
      question: "What are the store hours?  ",
      answer:
        "We are open every day from 10 AM to 8 PM",
      open: false
    },
    {
      question: "Do I need to create an account to use the website?",
      answer: "No, you don't need an account to use our website",
      open: false
    },
    {
      question: "What is your address?",
      answer: "We are located at 873 E Schaumburg Rd, Schaumburg, IL 60194",
      open: false
    },
    {
      question: "How can I contact you?",
      answer: "You can give us a call anytime at (630) 283-0440",
      open: false
    }

  ]);

  const toggleFAQ = index => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        }
        else {
          faq.open = false;
        }
        return faq;
      })
    );
  };









  return (
    <div className="App">

      <Navb />


      <div id="home"><CarouselPics /></div>
      <br />



      {/* <div class = "hero-about"> */}
      <br />

      <div id="about"><About /></div>

      {/* </div> */}

      <div class = "map-div">
      <iframe data-aos="zoom-in-up" data-aos-duration="1000"  data-aos-delay = "200"class="hero-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2963.7264130115645!2d-88.06158868432952!3d42.027601164023764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880faf049ef71c2d%3A0x9d593026b4b66d1b!2sTulsi%20Grocers!5e0!3m2!1sen!2sus!4v1663539726963!5m2!1sen!2sus" allowFullScreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>


  


      <div id="products"><Products /></div>

      <div id="reviews"><Reviews /></div>

      <br />
      {/* <div id="contact"> <Contact /></div> */}

      <br />
      <h2 class="header">FAQ</h2>
      <div id="faq" className="faqs">
        {faqs.map((faq, index) => (
          <FAQ  className = "faq-q-test-1"faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
        ))}
      </div>
      <br />
      <br />

      <Footer />




    </div>
  );
}

export default App;