import React, { useState , useEffect} from 'react';

import Aos from "aos";
import "aos/dist/aos.css"

function About() {

  useEffect(() => {
     
    Aos.init();
  }, []);


  return (
    <>
   
        <h2  data-aos="fade-right" data-aos-duration="1000" class="header">About Us</h2>

       
          <p data-aos="fade-right" data-aos-duration="1000" data-aos-delay="200" class = "about-text">Here at Tulsi Grocers, we are committed to proving the community with  Indian
            food at low and affordable prices. We have tons of fresh produce, frozen food, cooking ingredients, and much more. We receive new shipments often,
            and we are rarely out of stock of items. We are located in Schaumburg, IL, conveniently at the intersection of Schaumburg Rd and Plum Grove Rd.
            Come visit any time, and we will be happy to provide you with fresh food!</p>
       
        <h3 class="header-3" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="200">We are open 10 AM to 8 PM every day</h3>

    </>
  );
}

export default About;