function Footer() {
  return (
    <>
      <footer class="site-footer">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <h6>About</h6>
              <p class="text-justify">Here at Tulsi Grocers, we are committed to proving the community with  Indian
                food at low and affordable prices. We have tons of fresh produce, frozen food, cooking ingredients, and much more. We receive new shipments often,
                and we are rarely out of stock of items. We are located in Schaumburg, IL, conveniently at the intersection of Schaumburg Rd and Plum Grove Rd.
                Come visit any time, and we will be happy to provide you with fresh food!
              </p>
            </div>

            <div class="col-xs-6 col-md-3">
              {/* <h6>Categories</h6>
              <ul class="footer-links">
                <li><a href="http://scanfcode.com/category/c-language/">C</a></li>
                <li><a href="http://scanfcode.com/category/front-end-development/">UI Design</a></li>
                <li><a href="http://scanfcode.com/category/back-end-development/">PHP</a></li>
                <li><a href="http://scanfcode.com/category/java-programming-language/">Java</a></li>
                <li><a href="http://scanfcode.com/category/android/">Android</a></li>
                <li><a href="http://scanfcode.com/category/templates/">Templates</a></li>
              </ul> */}
            </div>

            <div class="col-xs-6 col-md-3">
              <h6>Quick Links</h6>
              <ul class="footer-links">
                <li><a href="#home">Home</a></li>
                <li><a href="#about">About Us</a></li>
                <li><a href="#products">Products</a></li>
                <li><a href="#reviews">Reviews</a></li>
                {/* <li><a href="contact">Contact Us</a></li> */}
                <li><a href="#faq">FAQ</a></li>
              </ul>


            </div>
          </div>

        </div>

















        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-6">
            <br></br>
              <p class="copyright-text">Copyright &copy; All Rights Reserved by Tulsi Grocers</p>
              <br></br>
              <p class="copyright-text">Developed by Karan Kashyap and Dev Patel</p>
            
            </div>

            <div class="col-xs-6 col-md-3">

            </div>

            <div class="col-xs-6 col-md-3">

            <br></br>
              <p class="copyright-text">873 E Schaumburg Rd, Schaumburg, IL 60194
              </p>

              <p class="copyright-text">Call us at (630) 283-0440
              </p>

            </div>
          </div>

        </div>












      </footer>
    </>

  );
}

export default Footer;