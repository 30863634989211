import firebase from "firebase/compat/app";
import "firebase/compat/firestore"
import {getAuth, GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyAkB7VjP3wVoQZsJbHJ0iFN3L6Us1vCLDc",
  authDomain: "tulsi-grocers.firebaseapp.com",
  projectId: "tulsi-grocers",
  storageBucket: "tulsi-grocers.appspot.com",
  messagingSenderId: "155388782612",
  appId: "1:155388782612:web:726bbd20a50f36a6161f01",
  measurementId: "G-1778G9986D"
};

const app = firebase.initializeApp(firebaseConfig);

const storage = getStorage(app);

export {storage, firebase as default};

