import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import firebase from "./Firebase"
import React, { useState, useEffect } from 'react';
import Aos from "aos";
import "aos/dist/aos.css"

function Reviews() {
  const [reviews, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const ref = firebase.firestore().collection("reviews");
  function getReviews() {
    

    setLoading(true);

    ref.onSnapshot((querySnapshot) => {
      const reviews = [];
      querySnapshot.forEach((doc) => {
        reviews.push(doc.data());
      });


      setItems(reviews);
      setLoading(false);
    
      });

  }


  useEffect(() => {
    getReviews();
    Aos.init({duration: 2000});
  }, []);

  if (loading) {
    return <h1> Loading...</h1>;
  }



  return (
    <>





      <section class="container-fluid">

        <section class>
          {/* <h2 class="page-header" > */}
          <h2 data-aos="fade-right" data-aos-duration="1000" data-aos-delay = "500" class="header" >
            Customer Reviews
          </h2>
        </section>


        <section class="row">


          {reviews.map((review) => (
              
            <section data-aos="flip-left" data-aos-duration="1200"   data-aos-delay = "1000" class="col-sm-3">
              <div class="panel panel-primary">
                <div class="panel-heading">

                  <section class="review-rating text-left">
                    <Rating className = "rating-stars" name="read-only" value={review.reviewStars} precision={0.1} readOnly />
                  </section>
                

                  <section className = "review-name">
                    <span>{review.reviewName}</span>
                  </section>
                </div>
                <div class="panel-body">
                  <p>{review.reviewReview}</p>
                </div>
                <div class="panel-footer text-right"><em>{review.reviewTime}</em></div>
              </div>
            </section>

          ))}





        </section>


        <section class="row text-center">

          {/* <button type="button" class="btn btn-primary-outline">Submit Review</button> */}
        </section>

      </section>

    </>
  );
}

export default Reviews;