import Box from '@mui/material/Box'
import React, { useState, useEffect } from 'react';
import firebase from "./Firebase"
import AOS from "aos";

function Products() {

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);



  const ref = firebase.firestore().collection("items");

  function getItems() {

    setLoading(true);

    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });

      AOS.init();
      setItems(items);
      setLoading(false);
    });

  }

  useEffect(() => {
    getItems();
  }, []);

  if (loading) {
    return <h1> Loading...</h1>;
  }



  return (
    <>


      <h2 data-aos="fade-right" data-aos-duration="1000"  class="header">Featured Products</h2>


      <section className="products-all">
        <section class="row" >




          {items.map((item) => (
          
            <div data-aos="flip-left" data-aos-duration="1200" data-aos-delay = "300"class="product-item">
              <div class="panel-heading">
                <h2 class="header-2">{item.name}</h2>
                <img
                  className="product-pics"

                  src={item.imageURL}

                  alt="Loading..."

                />


              </div>

              {/* <div class = "header-product-price-div"> */}
              <h4 class="header-product-price">${item.price}</h4>
              {/* </div> */}


            </div>

          ))}





        </section>
      </section>

    </>
  );
}

export default Products;