import React, { useState, useEffect, Fragment, useRef } from "react";
import "./App.css"
import firebase from "./Firebase.js"
import { signInWithGoogle } from "./Firebase.js";
// import { v4 as uuidv4 } from 'uuid';
import { v4 } from 'uuid';
import { Navigate } from 'react-router-dom';
import {
  ref, uploadBytes, uploadBytesResumable, getDownloadURL, listAll, doc, deleteDoc, list, deleteObject
} from "firebase/storage";
import { storage } from "./Firebase.js";
import "firebase/compat/firestore"
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';


// import { Switch, Route, Redirect } from "react-router-dom";


function Admin() {

  const auth = getAuth();

  const [loggedOut, setLoggedOut] = React.useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setNewItemName] = useState('');
  const [price, setNewItemPrice] = useState('');
  const [newItemID, setNewItemID] = useState('');
  const [newImageName, setNewImageName] = useState('');

  var tempID = "";


  const refItems = firebase.firestore().collection("items");

  const [imageUpload, setImageUpload] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [progress, setProgress] = useState(0);



  // const [values, setValues] = React.useState({
  //   name: '',
  //   email: '',
  //   message: ''
  // });

  // const handleChange = (prop) => (event) => {
  //   setValues({ ...values, [prop]: event.target.value });
  //   console.log(values)
  // };





  const [reviews, setReviews] = useState([]);
  const [reviewName, setNewReviewName] = useState('');
  const [reviewReview, setNewReviewReview] = useState('');
  const [reviewStars, setNewReviewStars] = useState('');
  const [reviewTime, setNewReviewTime] = useState('');
  const [newReviewID, setNewReviewID] = useState('');

  const refReviews = firebase.firestore().collection("reviews");
  var tempIDReview = "";


  const uploadFile = () => {

    if (imageUpload == null) return;

    const imageRef = ref(storage, `images/${newItemID}`);

    const uploadTask = uploadBytesResumable(imageRef, imageUpload);

    uploadTask.on('state_changed',
      (snapshot) => {

        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        console.log('Upload is ' + progress + '% done');
        if (progress < 100) {
          document.getElementById("itemAddingText").innerHTML = "Adding Item...";
        }
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      },
      (error) => {
      },
      () => {

        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

          console.log('File available at', downloadURL);
          // setImageURL(downloadURL);
          document.getElementById("itemAddingText").innerHTML = "";
          const imageURL = downloadURL;
          addItem({ name, price, imageURL, uid: newItemID });
        });


      }
    );
  };









  function signOutWithGoogle() {

    if (window.confirm("Are you sure you want to sign out?")) {
      localStorage.removeItem("name");
      localStorage.removeItem("email");
      localStorage.removeItem("profilePic");
      signOut(auth);
      setLoggedOut(true);
    }
  }

  function getItems() {
    setLoading(true);
    refItems.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setItems(items);
      setLoading(false);
    });
  }

  function getReviews() {
    setLoading(true);
    refReviews.onSnapshot((querySnapshot) => {
      const reviews = [];
      querySnapshot.forEach((doc) => {
        reviews.push(doc.data());
      });
      setReviews(reviews);
      setLoading(false);
    });
  }



  useEffect(() => {
    getItems();
    getReviews();
  }, []);

  if (loading) {
    return <h1> Loading...</h1>;
  }

  if (loggedOut) {
    return (
      <Navigate to="/login" />
    );
  }



  function addItem(newItem) {
    refItems
      .doc(newItem.uid)
      .set(newItem)
      .catch((err) => {
        console.error(err);
      });
    setNewItemID("");
    tempID = "";
    alert("Item added successfully!");
  }



  function addReview(newReview) {
    refReviews
      .doc(newReview.uid)
      .set(newReview)
      .catch((err) => {
        console.error(err);
      });
    setNewReviewID("");
    tempID = "";
    alert("Review added successfully!");
  }





  function editItem(updatedItem) {
    refItems
      .doc(updatedItem.uid)
      .update(updatedItem)
      .catch((err) => {
        console.error(err);
      });

  }

  function editReview(updatedReview) {
    refReviews
      .doc(updatedReview.uid)
      .update(updatedReview)
      .catch((err) => {
        console.error(err);
      });


      // function joeMama(){
      //   var joe = values.name
      // }
  }


  function addReviewFunc() {
    tempIDReview = v4();
    setNewReviewID(tempIDReview);
    console.log(newReviewID);
    addReview({ reviewName, reviewReview, reviewStars, reviewTime, uid: tempIDReview });



  }


  if (localStorage.getItem("email") == "tulsigrocer928@gmail.com" || localStorage.getItem("email") == "karan.kashyap.4899@gmail.com" || localStorage.getItem("email") == "devpatel05@gmail.com") {

    return (
      <>

        <div class="admin-site">


          <div class="admin-left-side">

            <div class="admin-top">
              <h1 class="admin-header"> Admin</h1>

              <button class="admin-button" onClick={signOutWithGoogle}>
                Sign Out
              </button>

              <h2 class="admin-header2">Manage food items on main website from here</h2>

{/* 
<TextField sx={{ m: 1, mb: 4, width: '12vw' }}
            variant="outlined"
            id="name-input-field"
            value={values.name}
            label="Name"
            onChange={handleChange('name')}
          /> */}

              <hr class="hr-1"></hr>
            </div>


            <div class="add-items">
              <h3 class="add-item-text">Products Manager</h3>

              <input
                type="text"
                placeholder={"Name"}
                id={"newName"}
                title="newName"
                size="40"
                value={name}
                onChange={(e) => setNewItemName(e.target.value)}
              />
              <br /><br />
              <input
                type="text"
                placeholder={"Price"}
                id={"newPrice"}
                title="newPrice"
                size="40"
                value={price}
                onChange={(e) => setNewItemPrice(e.target.value)}
              />
              <br />
              <br />

              <h4 class="upload-image-text">Add Image</h4>





              <input

                type={"file"}

                onChange={(event) => {
                  setImageUpload(event.target.files[0]);
                  tempID = v4();
                  setNewItemID(tempID);
                  console.log(newItemID)
                }}
              />
              <progress value={progress} max="100" />


              <br />
              <h3 id="itemAddingText"></h3>
              <br />

              <button class="admin-button" onClick={uploadFile}> Add Item</button>


              <hr class="hr-1"></hr>
            </div>





            <div class="add-reviews">
              <h3 class="add-item-text">Reviews Manager</h3>

              <input
                type="text"
                placeholder={"Name"}
                id={"newReviewName"}
                title="newReviewName"
                size="40"
                value={reviewName}
                onChange={

                  (e) => setNewReviewName(e.target.value)}
              />
              <br /><br />
              <input
                type="text"
                placeholder={"Review"}
                id={"newReviewReview"}
                title="newReviewReview"
                size="40"
                value={reviewReview}
                onChange={(e) => setNewReviewReview(e.target.value)}
              />
              <br /><br />
              
              <input
                type="text"
                placeholder={"Stars"}
                id={"newReviewStars"}
                title="newReviewStars"
                size="40"
                value={reviewStars}
                onChange={(e) => setNewReviewStars(e.target.value)}
              />
              <br /><br />
              <input
                type="text"
                placeholder={"Time"}
                id={"newReviewTime"}
                title="newReviewTime"
                size="40"
                value={reviewTime}
                onChange={(e) => setNewReviewTime(e.target.value)}
              />
              <br />
              <br />







              <button class="admin-button" onClick={addReviewFunc}>Add Review</button>



            </div>

          </div>






          <div class="items-and-reviews-scroll-box">
            <div class="all-items">
              <h2 class="add-item-text">Products</h2>


              {items.map((item) => (
                <div class="each-item" key={item.uid}>

                  <hr class="hr-2"></hr>
                  <p class="item-info">{item.name}</p>
                  <p class="item-info">${item.price}</p>
                  <img src={item.imageURL} style={{
                    width: '200px',
                    height: '200px'
                  }}></img>
                  <br /><br /><br />
                  <div class="button1-admin-spacing">
                    <button class="admin-button"
                      uid={item.uid}
                      onClick={() => {
                        if (name !== "" && price != "" && imageURL != "") {
                          editItem({ name, price, imageURL, uid: item.uid });
                        } else if (name !== "" && imageURL != "") {
                          editItem({ name, imageURL, uid: item.uid });

                        } else if (name !== "" && price != "") {
                          editItem({ name, price, uid: item.uid });

                        } else if (price !== "" && imageURL != "") {
                          editItem({ price, imageURL, uid: item.uid });

                        } else if (name !== "") {
                          editItem({ name, uid: item.uid });
                        } else if (price !== "") {
                          editItem({ price, uid: item.uid });
                        } else if (imageURL !== "") {
                          editItem({ imageURL, uid: item.uid });
                        }
                      }}
                    >
                      Edit Product
                    </button>
                  </div>
                  <button class="admin-button" uid={item.uid} onClick={() => {

                    if (window.confirm("Are you sure you want to delete this product?")) {
                      refItems.where("uid", "==", item.uid).get()
                        .then(querySnapshot => {
                          querySnapshot.docs[0].ref.delete();
                        });

                      const deleteImageRef = ref(storage, `images/${item.uid}`);

                      deleteObject(deleteImageRef).then(() => {
                        alert("Product deleted successfully");
                      }).catch((error) => {
                        console.log("error");
                      });


                    }


                    // refItems.where("uid", "==", item.uid).get()
                    // .then(querySnapshot => {
                    //     querySnapshot.docs[0].ref.delete();
                    // });
                  }


                  }>Delete Product</button>


                  <br />

                </div>

              ))}
            </div>



            <div class="all-reviews">
              <hr class="hr-1-1"></hr>
              <h2 class="add-review-text">Reviews</h2>


              {reviews.map((review) => (
                <div class="each-item" key={review.uid}>

                  <hr class="hr-2"></hr>
                  <p class="item-info">{review.reviewName}</p>
                  <p class="item-info">{review.reviewReview}</p>
                  <Rating name="read-only" value={review.reviewStars} precision={0.1} readOnly />
                  <p class="item-info">{review.reviewTime}</p>

                  <br /><br /><br />
                  <div class="button1-admin-spacing">
                    <button class="admin-button"
                      uid={review.uid}
                      onClick={() => {

                        if (reviewName !== "" && reviewReview != "" && reviewStars != "" && reviewTime !== "") {
                          editReview({ reviewName, reviewReview, reviewStars, reviewTime, uid: review.uid })
                        } else if (reviewName !== "" && reviewReview != "" && reviewStars != "") {
                          editReview({reviewName, reviewReview, reviewStars, uid: review.uid  })
                        } else if (reviewName !== "" && reviewReview != "" && reviewTime != "") {
                          editReview({ reviewName, reviewReview, reviewTime, uid: review.uid  })
                        } else if (reviewName !== "" && reviewStars != "" && reviewTime != "") {
                          editReview({ reviewName, reviewStars, reviewTime, uid: review.uid  })
                        } else if (reviewReview !== "" && reviewStars != "" && reviewTime != "") {
                          editReview({ reviewReview, reviewStars, reviewTime , uid: review.uid })
                        } else if (reviewName !== "" && reviewReview != "") {
                          editReview({ reviewName, reviewReview, uid: review.uid  })
                        } else if (reviewName !== "" && reviewStars != "") {
                          editReview({ reviewName, reviewStars , uid: review.uid })
                        } else if (reviewName !== "" && reviewTime != "") {
                          editReview({ reviewName, reviewTime , uid: review.uid })
                        } else if (reviewReview !== "" && reviewStars != "") {
                          editReview({ reviewReview, reviewStars, uid: review.uid  })
                        } else if (reviewStars !== "" && reviewTime != "") {
                          editReview({ reviewStars, reviewTime, uid: review.uid  })
                        } else if (reviewStars !== "" && reviewTime != "") {
                          editReview({ reviewStars, reviewTime, uid: review.uid  })
                        } else if (reviewName !== "") {
                          editReview({ reviewName , uid: review.uid })
                        } else if (reviewReview !== "") {
                          editReview({ reviewReview , uid: review.uid })
                        } else if (reviewStars !== "") {
                          editReview({ reviewStars, uid: review.uid  })
                        } else if (reviewTime !== "") {
                          editReview({ reviewTime , uid: review.uid })
                        }

                      }}
                    >
                      Edit Review
                    </button>
                  </div>
                  <button class="admin-button" uid={review.uid} onClick={() => {

                    if (window.confirm("Are you sure you want to delete this review?")) {
                      refReviews.where("uid", "==", review.uid).get()
                        .then(querySnapshot => {
                          querySnapshot.docs[0].ref.delete();
                        });
                        alert("Review deleted successfully");
                    }


                    // refItems.where("uid", "==", item.uid).get()
                    // .then(querySnapshot => {
                    //     querySnapshot.docs[0].ref.delete();
                    // });
                  }


                  }>Delete Review</button>


                  <br />

                </div>

              ))}
            </div>





          </div>



          {/* <div class="all-reviews">
            <h2 class="add-item-text">Items</h2>


            {items.map((item) => (
              <div class="each-item" key={item.uid}>

                <hr class="hr-2"></hr>
                <p class="item-info">{item.name}</p>
                <p class="item-info">${item.price}</p>
                <img src={item.imageURL} style={{
                  width: '200px',
                  height: '200px'
                }}></img>
                <br /><br /><br />
                <div class="button1-admin-spacing">
                  <button class="admin-button"
                    uid={item.uid}
                    onClick={() => {
                      if (name !== "" && price != "" && imageURL != "") {
                        editItem({ name, price, imageURL, uid: item.uid });
                      } else if (name !== "" && imageURL != "") {
                        editItem({ name, imageURL, uid: item.uid });

                      } else if (name !== "" && price != "") {
                        editItem({ name, price, uid: item.uid });

                      } else if (price !== "" && imageURL != "") {
                        editItem({ price, imageURL, uid: item.uid });

                      } else if (name !== "") {
                        editItem({ name, uid: item.uid });
                      } else if (price !== "") {
                        editItem({ price, uid: item.uid });
                      } else if (imageURL !== "") {
                        editItem({ imageURL, uid: item.uid });
                      }
                    }}
                  >
                    Edit Item
                  </button>
                </div>
                <button class="admin-button" uid={item.uid} onClick={() => {

                  if (window.confirm("Are you sure you want to delete this product?")) {
                    refItems.where("uid", "==", item.uid).get()
                      .then(querySnapshot => {
                        querySnapshot.docs[0].ref.delete();
                      });

                    const deleteImageRef = ref(storage, `images/${item.uid}`);

                    deleteObject(deleteImageRef).then(() => {
                      alert("Item deleted successfully");
                    }).catch((error) => {
                      console.log("error");
                    });


                  }


                  // refItems.where("uid", "==", item.uid).get()
                  // .then(querySnapshot => {
                  //     querySnapshot.docs[0].ref.delete();
                  // });
                }


                }>Delete Item</button>


                <br />

              </div>

            ))}
          </div> */}



        </div>









      </>
    );

  } else {
    return (
      
        <h1>Please log in through <a href="/login">Login</a></h1>

     
    );
  }

}

export default Admin;
