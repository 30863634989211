import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css'
import Image from 'react-bootstrap/Image'
import NavDropdown from 'react-bootstrap/NavDropdown';
import { requirePropFactory } from '@mui/material';

import { Link, BrowserRouter as Router, Routes, Route } from "react-router-dom";


import About from "./About.js";



function Navb() {
  return (
    <>

      <div class="container-nav-bar">

        <Navbar bg = "light" className="color-nav" expand="lg" >
          <Container>
            <Navbar.Brand className="navbar-title" href="#home">
              Tulsi
              &nbsp;

              <img
                src={require('./images/leaf.png')}
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{' '}
              Grocers


            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">

              <Nav className="me-auto">

                <Nav.Link className="nav-link" href="#home">Home</Nav.Link>
                <Nav.Link className="nav-link" href="#about">About Us</Nav.Link>
                <Nav.Link className="nav-link" href="#products">Products</Nav.Link>
                <Nav.Link className="nav-link" href="#reviews">Reviews</Nav.Link>
                {/* <Nav.Link className="nav-link" href="#contact">Contact Us</Nav.Link> */}
                <Nav.Link className="nav-link" href="#faq">FAQ</Nav.Link>

                {/* <Nav.Link className = "nav-link" path="/admin">Admin</Nav.Link> */}

                {/* <Nav.Link>
        <Link to='/admin' className = "admin-link"></Link>          
        </Nav.Link> */}
              </Nav>

            </Navbar.Collapse>
          </Container>
        </Navbar>


      </div>


    </>
  );
}

export default Navb;